@import '../../../Assets/css/mixins';

.gerx-menubar {
    display: flex;
    flex-direction: row;
    width: 100%;
 
    .navbar {
        width: 100%;
        background-image: linear-gradient(to right, white, $lightgreen, $darkgreen);

        .nav-link {
            color: #e0e0e0;
            
            &:active {
                color: white;
            }

            &:hover {
                color: white;
            }

            &:focus {
                color: white;
            }
        }

        .btn-link {
            font-size: small;
        }
    }
}