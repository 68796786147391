body {
    font-family: Helvetica, sans-serif;
}

.search-bar {
  display: flex;
  flex-direction: row;

  .button {
    padding: 4px 0 0 8px;
  }
}

.search-term .badge {
  margin-left: 8px;
  font-size: 16px;
  color: white;
  background-color: #28a745;

  &:hover {
    background-color: #909090;
  }

  i {
    margin-left: 8px;

    &:hover {
      cursor: pointer;
    }
  }
}

.react-autosuggest__container {
  position: relative;

  & input {
    font-size: 14px;
  }
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 30px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-container {
  background-color: #f9f9f9
}

.react-autosuggest__section-title {
  font-weight: bold;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 12px 0 0 10px;
  font-size: 12px;
  color: #777;
}