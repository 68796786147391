.filter-bar {
    text-align: center;

    .badge {
        color: #f0f0f0;
        font-size: 14px;
        cursor: pointer;
        padding: 6px 8px;
        width: 120px;

        &:hover {
            color: white;
            background-color: #909090;
        }
    }
}