.table input {
    color: black;
    background-color: #CCCCCC;
    padding: 4px;
}

.btn {
    margin-left: 16px;
    vertical-align: bottom;
}

.gerx-contacts {
    margin-top: 16px;

    th {
        color: white;
        background-color: grey;
        text-align: center;
    }

    td:hover {
        background-color: #d0d0d0;
        cursor: pointer;
    }
}