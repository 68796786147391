@mixin shadowed-box {
    box-shadow: 1px 1px 1px 1px #a0a0a0
}

.gerx-chat {
    padding: 8px 12px;
    margin-bottom: 34px;
    width: 100%;

    h6 {
        font-family: Tahoma, sans-serif;
        font-weight: bold;
    }

    .gerx-chat-contacts-panel {
        margin-top: 8px;
        padding-bottom: 8px;
        height: auto;
        padding-right: 0;

        .flex-column {
            padding: 8px;
            background-color: white;
        }
        
        .gerx-contacts-column {
            @include shadowed-box();
            overflow-y: auto;
            height: 100%;

            .gerx-chat-contacts {
                &.nav-item {
                    border: 0;

                    &:not(:last-child) {
                        border-bottom: 1px;
                        border-color: #a0a0a0;
                        border-style: solid;
                    }
                    
                    .nav-link {
                        color: blue;
                        display: flex;
                        flex-direction: row;
                        padding: 8px;
                        
                        &.active {
                            background-color: #ececec;
                        }

                        .gerx-contact {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            flex-grow: 1;
                            height: 60px;

                            .contact-photo {
                            }
                            
                            .contact-info {
                                display: flex;
                                flex-direction: column;
                                font-size: 14px;
                                flex-grow: 1;

                                .contact-name {
                                }

                                .contact-body {
                                    color: grey;
                                }
                            }

                            .contact-status {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: flex-end;

                                .contact-count {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                }
            
                                .contact-time {
                                    font-size: 10px;
                                    color: grey;
                                    margin-left: 4px;
                                }
                            }

                            &.unregistered {
                                color: grey;
                            }
                        }
                    }
                }
            }
        }
    }

    .gerx-chat-contents-panel {
        margin-top: 8px;

        .gerx-chat-messages {
            @include shadowed-box();
            background-color: #e0e0e0;
            padding: 10px;
            overflow-y: scroll;
            margin-bottom: 16px;

            .message {
                color: black;
                display: flex;
                margin-top: 8px;

                .card {
                    max-width: 80%;
                    @include shadowed-box();

                    .card-header {
                        background-color: #f0f0f0;
                        display: flex;
                        flex-direction: row;
                        padding: 4px 12px;
                        align-items: baseline;
                    }
    
                    .card-body {
                        padding: 4px 12px;

                        .msg-body {
                            font-size: 15px;
                        }

                        p {
                            margin-bottom: 0;
                        }
                    }
                }

                &.msg-sender {
                    color: black;
                    text-align: right;
                    justify-content: flex-end;

                    .contact-photo {
                        display: none;
                    }

                    .card-header {
                        color: #707070;
                        justify-content: flex-end;
                    }

                    .msg-body {
                        color: black;
                        justify-content: flex-end;
                        font-size: 15px;
                    }

                    .msg-read {
                        color: #606060;
                        margin-left: 8px;
                        margin-bottom: 8px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        
                        &.not-read {
                            color: transparent;
                        }
                    }    
                }

                &.msg-recipient {
                    color: #707070;

                    .msg-read {
                        display: none;
                    }

                    .msg-body {
                        color: black;
                    }
                }

                &.msg-system {
                    justify-content: center;

                    .card-header {
                        color: white;
                        background-color: #a0a0a0;
                        padding: 4px 16px;
                    }

                    .card-body {
                        color: black;
                        padding: 4px 16px;
                        background-color: #f5f5f5;
                    }

                    .msg-body {
                        font-weight: bold;
                        font-size: 14px;
                    }
                }

                .msg-name {
                    font-size: 14px;
                    font-weight: bold;
                }

                .msg-time {
                    font-size: 12px;
                    margin-left: 4px;
                }
            
                .msg-body {
                    font-size: 16px;
                    font-weight: normal;
                }
            }
        }
        
        .msg-note {
          text-align: center;
          justify-content: center;
          font-weight: bold;
          font-size: 14px;
          display: flex;
          justify-items: center;

          span {
            color: red;
            padding: 8px 24px;
            background-color: white;
            display: inline-block;
            max-width: 50%;
          }
        }

        .messaging {
            display: flex;
            flex-direction: row;
            position: absolute;
            width: calc(100% - 30px);
            padding-bottom: 16px;

            .messaging-box {
                @include shadowed-box();
                // z-index: 1;
                vertical-align: bottom;
            }

            .send-btn {
                border-radius: 24px;

                &.disabled {
                  background-color: green;
                }
            }
            
            .msg-file-input {
                padding: 8px 0 0 12px;
                &:hover {
                    cursor: pointer;
                }
            }            
        }
        
        .msg-files {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            font-size: 12px;
            background-color: #d0d0d0;
            font-weight: bold;

            &.attach {
              margin-top: 8px;
              padding: 8px;              
            }

            &.download {
              margin-top: 8px;
              background-color: transparent;
              justify-content: flex-end;
              display: flex;

              .msg-file {
                justify-content: flex-end;
                background-color: #e0e0e0;
                padding: 4px 8px 0 8px;
              }
            }

            .msg-file {
                margin: 0px 4px 4px 0;
                border-radius: 8px;
                background-color: #f9f9f9;
                padding: 4px 8px;

                .msg-filename {
                    font-size: 10px;
                    color: blue;
                }

                .msg-cross {
                    color: black;
                    padding: 8px 0 8px 6px;
                    cursor: pointer;
                }
            }
            
            .msg-files-note {
                flex-basis: 100%;
                width: 100%;
                color: black;
                padding-top: 8px;
            }
        }
    }

    .contact-photo {
        margin-right: 12px;
        background-color: #f8f8f8;
        box-shadow: 0px 0px 1px 1px grey;
        padding: 9px 10px;
        font-size: 14px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: bold;
        color: #707070;
        height: 38px;
        width: 38px;
        border-radius: 50%;
    }

    .dot {
        z-index: 1;
        position: relative;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        left: 14px;

        &.online {
            background-color: green;
            border: white 1px solid;
        }
        &.offline {
            background-color: lightgray;
            border: grey 1px solid;
        }
    }
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 11px;
    height: 30px;
}
  
.separator::before,
.separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid white;
}
  
.separator:not(:empty)::before {
    margin-right: .25em;
}
  
.separator:not(:empty)::after {
    margin-left: .25em;
}