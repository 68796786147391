  /****** Project Overview page above  ******/
  /****** Planting Site page: ******/
  .add-planting-btn {
    margin: 1rem;
    min-width: 140px;
  }
  
  .edit-planting-map-btn {
    margin: 1rem;
  }
  
  .close-edit-planting-map-btn {
    margin: 1rem;
    float: right;
  }
  
  .add-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;

    label {
        margin-right: 16px;
        width: 100%;
    }
  }
  
  .add-form-margin {
    margin-left: 1rem;
  }
  /****** Planting Site page above  ******/