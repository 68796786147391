.inline-block {
    display: inline-block;
  }
  
  .map-btns {
    float: right;
  }
  
  .btn-margin {
    margin: .5rem;
  }
  
  .esri-icon-cursor {
    display: none;
  }
  
  .esri-sketch__section:first-child {
    display: none;
    padding: 0;
    margin: 0;
  }
  
  /* .esri-sketch__panel  esri-sketch__section:first-child{
    padding: 0;
    margin: 0;
  } */