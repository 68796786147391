._2Jtxm ._2kL0S, ._2Jtxm ._2JvrO {
    width: 32px;
    height: 32px;
    font-size: 14px;
}

._2Jtxm ._1hzhf {
    font-size: 12px;
}

._2_g61 {
    padding: 0;
}

._53Ji7 {
    padding: 24px 0;
}

.hidden {
    display: none;
}