.new-reports {
    margin-top:20px;
    height: 105%;
    display: flex;
    flex-direction: column;
    // background-color: #ebf1f5;

    .charts {
        display: flex;
        justify-content: space-evenly;
        margin: 0 20px;
        height: 100%;
        min-height: 768px;
        
        // background-color: white;
        margin: 1% 3%;
        display: flex;
        justify-content: space-around;
        border-radius: 4px;
    }
      
    #barChart {
    width: 42%;
    max-height: 850px;

        div {
            position: static !important;        
        }
    }

    #geoChart {
    width: 42%;
    max-height: 850px;

        div {
            position: static !important;        
        }
    }

    #lineChart {
        width: 60%;
        max-height: 850px;
    
            div {
                position: static !important;        
            }
    }

    #textChart4 {
        width: 60%;
        max-height: 850px;
    
            div {
                position: static !important;        
            }
    }

    #textChart5 {
        width: 60%;
        max-height: 850px;
    
            div {
                position: static !important;        
            }
    }

    #textChart6 {
        width: 60%;
        max-height: 850px;
    
            div {
                position: static !important;        
            }
    }

    #textChart8 {
        width: 60%;
        max-height: 950px;
    
            div {
                position: static !important;        
            }
    }

    #textChart9 {
        width: 50%;
        max-height: 950px;
    
            div {
                position: static !important;        
            }
    }

    #textChart10 {
        width: 50%;
        max-height: 950px;

            div {
                position: static !important;        
            }
    }

    #textChart11 {
        width: 50%;
        max-height: 950px;
    
            div {
                position: static !important;        
            }
    }

    #textChart12 {
        width: 50%;
        max-height: 950px;
    
            div {
                position: static !important;        
            }
    }

    #textChart13 {
        width: 50%;
        max-height: 950px;
    
            div {
                position: static !important;        
            }
    }

    #textChart14 {
        width: 50%;
        max-height: 950px;
    
            div {
                position: static !important;        
            }
    }

    #textChart15 {
        width: 50%;
        max-height: 950px;
    
            div {
                position: static !important;        
            }
    }

}
