@import '../../Assets/css/mixins';

html, body {
    height: 100%;
}
  
.App .App-Content {
  min-height: 50vh;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  padding: 10px;
}

.initals-spacing {
  margin: auto;
}


.scroll-table {
  overflow: auto;
  // height: 80%;
  width: 100%;
}

.sidebar-links {
  color: gray;
  float: left;
  font-weight: bold;
}

.sidebar-links:hover {
  color: #074a49;
  text-decoration: none;
}

.sidebar-link-color {
  text-decoration: none;
  color: #074a49;
}

.full-width {
  width: 100%;
}

.App-Footer {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  background-color: #282c34;
  min-height: 5vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.App-Header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.title-bkgd {
  background-color: $medium_dark_green;
  color: white;
  padding: 8px 0;

  &.row > div {
    border-right: 1px;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;
    border-color: #e0e0e0;
    border-style: solid;
  }
}

.center-spinner {
  margin: auto;
  align-items: center;
  align-content: center;
  text-align: center;
}

.list-title {
  font-weight: bolder;
}

/****** navbar custom styles below ******/
.App-logo-nav {
  max-width: 200px;
}

.nav-spacing {
  display: none;
}

.nav-styles {
  color: #13140f;
  font-weight: bold;
}

.nav-styles:hover {
  color: #074a49;
  text-decoration: none;
}

.row-width-all {
  width: 100%;
}

.nav-toggle{
  margin-left: .5rem;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.login-btn-shape {
  height: 3rem;
  width: 3rem;
  border-radius: 1.5rem;
}
/****** navbar custom styles above ******/
/****** Search in Home ******/

.auto-title {
  font-weight: bold;
}

.hidden {
  display: none;
}

.search-input {
  width: 100%;
}

.auto-list {
  background-color: #DDD;
  width: 33%;
  overflow: scroll;
}

.auto-list-inactive {
  width: 33%;
}

.overlay {
  position: absolute;
  z-index: 10;
}
/****** Search in Home above ******/
/****** Home components ******/
.home {
  .react-bootstrap-table {
    margin-bottom: 16px;
    overflow-y: auto;
    border: #a0a0a0 1px solid;
    min-height: 500px;

    .table {
      width: 1800;

      th {
        background-color: $medium_dark_green;
      }

      thead > tr > th {
        position: sticky;
        top: 0px;
      }
    }
  }

  .rw-multiselect-input {
    display: none;
  }

  .rw-multiselect-tag-label {
    font-size: 10px;
  }
  
  .rw-list-option {
    font-size: 10px;
    text-align: left;
  }

  .css-yk16xz-control, .css-1pahdxg-control {
    background-color: white;
    font-size: 10px;
  }

  .css-gx40ey-MenuList {
    border-radius: 5px;
    background-color: grey;
    border: 1px solid black;
    font-size: 12px;
  }

  .css-1n7v3ny-option {
    background-color: lightgray;
  }

  .select__menu.css-26l3qy-menu {

    .css-gx40ey-MenuList {
      border-radius: 5px;
      background-color: grey;
      border: 1px solid black;
      font-size: 12px;
      
      .select__option.select__option--is-focused.css-1n7v3ny-option {
        background-color: lightgray;
      }
    }


  }
}
.z-index-table {
  z-index: 15;
}

.search-term {
  margin-top: 8px;

  label {
    font-size: 18px;
    font-weight: bold;
  }
}

.btn-styles {
  align-items: center;
  text-align: center;
  align-content: center;
}

.btn-look {
  margin-bottom: .5rem;
  border: 1px solid #CCC;
  border-radius: .25rem;
  background-color: #DDD;
}

.btn-margin-top {
  margin-top: 16px;
}

.list {
  cursor: pointer;
}

.list:hover {
  background-color: #e0e0e0;
}

.margin-table-bottom {
  // margin-bottom: 92px;
}

.title-break {
  word-wrap: break-word;
}

@media (max-width: 580px) {
  .hide-sx {
    display: none;
  }
}
/****** Home buttons above ******/
/****** Project Overview page: ******/
.user-avatar {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.display-inline {
  display: inline;
}

.display-prog {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: gray;
}

.media-prog {
  color: gray;
  margin: 2rem;
  padding: 2.5rem;
}

.project-nav {
  background: white;
  color: #b3b7b3;
}

.bold-labels {
  font-weight: bolder;
}

.landholder-addr-style {
  display:inline;
}

.gerx-contents-panel {
  z-index: 10;
  margin-top: 0px;
  margin-bottom: 10rem;
}

.longitude-points {
  padding: .25rem;
  color: #AAA;
  word-wrap: normal;
  height: 200px;
  overflow-y: auto;
  border: 1px solid lightgray;
}

.sum-space {
  margin-left: 2.5rem;
  margin-right: 2rem;
}

.label-spacing-comments {
  margin-bottom: 2rem;
}

.planting-sum {
  border: none;
}

/****** Notes & Files page: ******/
.notes-title {
  width: 100%;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  color: #074a49;
  text-align: center;
}

.new-note-button {
  float: right;
  border: 1px solid #CCC;
  border-radius: 5px;
}

.cancel-note-button {
  float: left;
  border: 1px solid #CCC;
  border-radius: 5px;
}

.table-notes {
  overflow: scroll;
  overflow:scroll;
  padding:5px;
  max-height: 200px;
}

.form-group {
  .form-label {
      font-size: 14px;
      font-weight: bolder;
  }
}

.container-fluid {
  margin-bottom: 100px;
}

.form-label {
  font-size: 14px;
  font-weight: bold;
  vertical-align: bottom;
}

.form-control {
  font-size: 14px;
}

.gerx-value-label.form-label {
  font-size: 12px;
  font-weight: normal;
  vertical-align: bottom;
}

.gerx-hand-pointer {
  cursor: pointer
}

.gerx-button-group {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.react-bootstrap-table>table.table {
  table-layout: auto;
}
  
.gerx-add-container form .row div {
  padding-bottom: 8px;
}

.gerx-add-container form .row div .gerx-button-group {
    padding-bottom: 0;
}

.gerx-add {
    background-color: #d0d0d0;
    border: grey 1px solid;
    height: auto;

    .gerx-add-container {
        padding: 24px;
    }

    &.closed {
        height: 0;
        border: 0;
    }

    &.opened, &.closed {
        overflow-y: hidden;
        -webkit-transition: height 0.5s ease-in-out;
        -moz-transition: height 0.5s ease-in-out;
        -o-transition: height 0.5s ease-in-out;
        transition: height 0.5s ease-in-out;
        overflow-y: hidden;   
    }
}

.table {
  margin-top: 16px;

  th {
      color: white;
      background-color: $medium_dark_green;
      text-align: center;
  }

  tr:hover {
    background-color: #d0d0d0;
  }
}

.table-striped tbody tr:hover {
  color: blue;
}

.table-striped tbody tr:nth-of-type(odd):hover {
  color: blue;
  background-color: #d0d0d0;
}

.react-bootstrap-table {
  tr {
    td {
        cursor: pointer;
    }
  }
  
  .saving {
    td, th, th.sortable {
        cursor: progress;
    }
  }
}

.container, .container-fluid {
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }
  
    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }
  
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }  
}

.overview {
  .dropdown {
    margin-bottom: 5px;
    
    button {
      margin-left: 5px;
    }
  }
}
  /****** Notes & Files page above  ******/