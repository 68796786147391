.overview-map {
  .list-group {
    height: 240px;
    overflow-y: auto;
    border: 1px solid lightgray;
  }

  .main-location {
    background-color: #f0f0f0;
  }

  .waiting {
    height: 100%;
  }

  .inline-block {
    display: inline-block;
  }

  .map-btns {
    float: right;
  }

  .btn-margin {
    margin: .5rem;
  }

  .esri-icon-cursor {
    display: none;
  }
  .esri-sketch__section:first-child{
    padding: 0;
    margin: 0;
  }
}