.property-map {
  display: flex;
  flex-direction: column;

  .map-btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 12px 20px;
  }  
}