.inline-block {
  display: inline-block;
}

.map-btns {
  float: right;
}

.btn-margin {
  margin: .5rem;
}

.esri-icon-cursor {
  display: none;
}
.esri-sketch__section:first-child{
  padding: 0;
  margin: 0;
}

button.esri-search__submit-button.esri-widget--button {
  display: none;
}

.esri-icon-search {
  display: none;
}

.esri-view .esri-view-root {
  margin: 0px 20px;
}

div.list-group {
	margin: 0px 20px;
}