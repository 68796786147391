@import '../../../Assets/css/mixins';

.gerx-add-container form .row div {
    padding-bottom: 2px;
}

.gerx-add-container form .row div .gerx-button-group {
    padding-bottom: 0;
}

.table input {
    color: black;
    background-color: #CCCCCC;
    padding: 4px;
}

.btn {
    margin-left: 16px;
    vertical-align: bottom;
}

#select-filter-column-userOrgContactId, #select-filter-column-contactMode {
	background-color: #cbcbcb;
}

.gerx-add {
    background-color: #d0d0d0;
    border: grey 1px solid;

    .gerx-add-container {
        padding: 24px;
    }

    &.closed {
        height: 0;
        border: 0;
    }

    &.opened, &.closed {
        overflow-y: hidden;
        -webkit-transition: height 0.5s ease-in-out;
        -moz-transition: height 0.5s ease-in-out;
        -o-transition: height 0.5s ease-in-out;
        transition: height 0.5s ease-in-out;
        overflow-y: hidden;   
    }
}

.gerx-notes {
    margin-top: 16px;

    th {
        color: white;
        background-color: $medium_dark_green;
        text-align: center;
    }

    td:hover {
        background-color: #d0d0d0;
    }

    table {
        overflow-x:scroll;
        width: 700px;
    }
}